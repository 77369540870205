<template>
  <form
    class="subscribe__form form"
    method="POST"
    accept-charset="UTF-8"
    action="/subscribe/"
    @submit="validateForm"
  >
    <div class="subscribe__item is-half">
      <input
        aria-label="First Name"
        aria-describedby="footer_firstname_error"
        class="input is-small"
        type="text"
        placeholder="First Name*"
        name="firstname"
        v-model.trim="firstname"
        required
      />
      <span
        aria-live="assertive"
        class="alert is-small"
        id="footer_firstname_error"
        role="alert"
        v-if="error_firstname"
      >Please enter a valid first name.</span>
    </div>
    <div class="subscribe__item is-half">
      <input
        aria-label="Last Name"
        aria-describedby="footer_lastname_error"
        class="input is-small"
        type="text"
        placeholder="Last Name*"
        name="lastname"
        v-model.trim="lastname"
        required
      />
      <span
        aria-live="assertive"
        class="alert is-small"
        id="footer_lastname_error"
        role="alert"
        v-if="error_lastname"
      >Please enter a valid last name.</span>
    </div>
    <div class="subscribe__item select is-small is-quarter">
      <select
        aria-label="State"
        aria-describedby="footer_state_error"
        name="state"
        v-model="state"
        required
      >
        <option value="" disabled selected hidden>State*</option>
        <option value="ACT">ACT</option>
        <option value="NSW">NSW</option>
        <option value="NT">NT</option>
        <option value="QLD">QLD</option>
        <option value="SA">SA</option>
        <option value="TAS">TAS</option>
        <option value="VIC">VIC</option>
        <option value="VIC">WA</option>
      </select>
      <span
        aria-live="assertive"
        class="alert is-small"
        id="footer_state_error"
        role="alert"
        v-if="error_state"
      >Please select a state.</span>
    </div>
    <div class="subscribe__item is-three-quarters">
      <input
        aria-label="Email Address"
        aria-describedby="footer_email_error"
        class="input is-small"
        type="email"
        placeholder="Email*"
        name="email"
        v-model.trim="email"
        required
      />
      <span
        aria-live="assertive"
        class="alert is-small"
        id="footer_email_error"
        role="alert"
        v-if="error_email"
      >Please enter a valid email.</span>
    </div>
    <div class="subscribe__item is-fullwidth">
      <input
        type="checkbox"
        id="consent"
        aria-describedby="footer_consent_error"
        name="consent"
        v-model="consent"
        required
      >
      <label for="consent" class="subscribe__label">
        I consent to the <a href="/privacy/">ADF privacy policy</a>
      </label>
      <span
        aria-live="assertive"
        class="alert is-small"
        id="footer_consent_error"
        role="alert"
        v-if="error_consent"
      >Consent is required to subscribe.</span>
    </div>
    <input type="hidden" name="csrfmiddlewaretoken" :value="csrf_token">
    <button
      class="subscribe__submit button is-small is-red"
      type="submit"
      title="Sign Up"
    >
      Sign up
    </button>
  </form>
</template>

<script>
export default {
  name: 'subscribe-form',
  props: ['csrf_token'],
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      state: '',
      consent: false,
      error_firstname: false,
      error_lastname: false,
      error_email: false,
      error_state: false,
      error_consent: false,
    };
  },
  methods: {
    /**
     * Sanitize string
     */
    sanitize(str) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;',
      };
      return str.replace(/[&<>"']/g, (m) => map[m]);
    },
    /**
     * Validate string
     */
    validateString(str) {
      return (str.length > 0);
    },
    /**
     * Validate email address
     */
    validateEmail(email) {
      const re = /^.+@.+\..+$/;
      return re.test(email);
    },
    /**
     * Validate form
     */
    validateForm(e) {
      this.firstname = this.sanitize(this.firstname);
      this.lastname = this.sanitize(this.lastname);
      this.state = this.sanitize(this.state);
      this.error_firstname = !this.validateString(this.firstname);
      this.error_lastname = !this.validateString(this.lastname);
      this.error_state = !this.validateString(this.state);
      this.error_email = !this.validateEmail(this.email);
      this.error_consent = !this.consent;
      if (
        this.error_firstname
        || this.error_lastname
        || this.error_state
        || this.error_email
        || this.error_consent
      ) {
        e.preventDefault();
      }
    },
  },
};
</script>
