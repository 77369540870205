<template>
  <li class="drugs__item drugs__type" :class="drugFactClass" v-if="isFiltered">
    <a :href="fact.url" class="drugs__drug">
      <div class="drugs__icon" role="presentation">
        <div class="drugs__image"
          :style="`background-image: url('${drug_image}');`" role="presentation"></div>
      </div>
      <h3 class="drugs__name"><span>{{ fact.title }}</span></h3>
    </a>
  </li>
</template>

<script>
export default {
  name: 'drug-type',
  props: ['fact', 'filter'],
  computed: {
    drug_image() {
      if (this.fact.header_image) {
        return this.fact.header_image.url;
      }
      return '';
    },
    drugFactClass() {
      try {
        const drugType = this.fact.drug_type.name.toLowerCase();
        return `drugs__fact--${drugType}`;
      } catch (error) {
        return '';
      }
    },
    /**
     * should drug type be filtered or not?
     *
     * Returns true if no filters or filter equals drug type name
     */
    isFiltered() {
      if (!this.fact.drug_type) {
        return false;
      }
      if (this.filter === '') {
        return true;
      }
      return this.fact.drug_type.name === this.filter;
    },
  },
};
</script>
